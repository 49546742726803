import React, { useEffect } from 'react'
import Hero from './hero-team-subpage'
import Introduction from './introduction-team-subpage'
import Investments from './investments-team-subpage'
import NewsPostPreviewGrid from '../components/news-post-preview-grid'
import { Textfit } from 'react-textfit'
import { useMediaQuery } from 'react-responsive'

import styles from './person.module.css'
import heroStyles from './hero.module.css'
import PersonFeaturing from './PersonFeaturing/PersonFeaturing'
import Video from './video/video'
import headerStyles from './header.module.css'
function Person ({ profileColor, _rawBio, name, focus, youtubePlayer, videoId, mobileVideoId, position, department, socialLinkedin, socialInstagram, socialSubstack, socialTwitter, relatedCompaniesSort, relatedArticles, personArticles }) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => {
    const hero = document.querySelector(`.${heroStyles.heroTeamSubpage}`)
    const handleScroll = () => {
      window.requestAnimationFrame(() => {
        const currentScrollY = window.scrollY

        if (currentScrollY > hero.offsetHeight) {
          document.getElementsByTagName('header')[0].classList.remove(headerStyles.redHead)
        } else {
          document.getElementsByTagName('header')[0].classList.add(headerStyles.redHead)
        }
      })
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className={`${styles.grayishWhiteBackground}`}>
      <Hero className={`${!videoId ? heroStyles.noVideo : ''}`}>
        <div className={`${heroStyles.heroTeamSubpage}`}>
          <div className={`${heroStyles.heroTeamSubpageName}`}>
            <Textfit max={108} mode='single'>{name}</Textfit>
          </div>
          <div className={`${heroStyles.heroTeamSubpagePosition}`}>
            {/* eslint-disable-next-line eqeqeq */}
            {position}{department.length > 0 ? ',' : ''} <span>{department == 'founders' ? 'Founders + Emeritus' : department.join(', ').replace('-', ' ')}</span>
          </div>
          <div className={`${heroStyles.heroTeamSubpageVideoWrapp} ${!videoId ? heroStyles.noVideo : ''}`}>
            {(videoId
              ? <Video
                  addClass='heroTeamSubpageVideo' type={youtubePlayer ? 'youtube' : 'standard'}
                  videoId={isMobile && mobileVideoId ? mobileVideoId : videoId} mute={1} muteButton={1}
              />
              : <img src={profileColor.asset.url} alt='' />
            )}
          </div>
        </div>
      </Hero>

      <Introduction
        focus={focus} content={_rawBio} instagram={socialInstagram} medium={socialSubstack}
        twitter={socialTwitter} linkedIn={socialLinkedin}
      />

      {(relatedCompaniesSort?.length > 0) && (
        <Investments name={name.split(' ')[0]} content={relatedCompaniesSort} />
      )}

      {personArticles.length > 0 && (
        <NewsPostPreviewGrid
          name={name.split(' ')[0]}
          showSocials={false}
          nodes={personArticles}
          browseMoreHref='/news/'
          showMax={3}
        />
      )}
      {relatedArticles.length > 0 && (
        <PersonFeaturing nodes={relatedArticles} name={name.split(' ')[0]} />
      )}
    </div>
  )
}

export default Person
