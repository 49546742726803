import React, { useEffect, useMemo, useState } from 'react'
import Container from './container'
import styles from './investments.module.css'
import { Image } from './Image'
import { isExternalUrl } from '../lib/helpers'

function Investments ({ name, content }) {
  const [selectedItem, setSelectedItem] = useState()
  const [mainLetterList, setMainLetterList] = useState([])
  const [nonNullCount, setnonNullCount] = useState(4)

  const createMainLetters = (content) => {
    const result = []
    let lastLetter = ''

    content.map(({ company }) => {
      const currentLetter = company.title[0].toUpperCase()
      if (currentLetter > lastLetter) {
        result.push(currentLetter)
        lastLetter = currentLetter
      } else {
        result.push(' ')
      }
    })

    return result
  }

  const getSiteAddressByLink = (link) => {
    const linkArray = link.split('://')
    const linkAddress = linkArray[1]
    const result = linkAddress.substring(0, linkAddress.length - 1)
    return result
  }

  const filterContent = (contentArray) => {
    if (contentArray.length === 0) {
      return []
    }

    const uniqueItems = contentArray.reduce((acc, current) => {
      const x = acc.find(item => item.company.title === current.company.title)
      if (!x) {
        acc.push(current)
      }
      return acc
    }, [])

    const result = JSON.parse(JSON.stringify(uniqueItems))

    result.sort((firstItem, secondItem) => {
      if (firstItem.order > secondItem.order) {
        return -1
      }
      if (firstItem.order < secondItem.order) {
        return 1
      }
      const firstTitle = firstItem.company.title.toUpperCase()
      const secondTitle = secondItem.company.title.toUpperCase()

      if (firstTitle < secondTitle) {
        return -1
      }
      if (firstTitle > secondTitle) {
        return 1
      }
      return 0
    })

    return result
  }

  const filteredContent = useMemo(() => {
    const filteredList = filterContent(content)
    const letterList = createMainLetters(filteredList)
    setMainLetterList(letterList)
    if (filteredList.length > 0) {
      setSelectedItem(filteredList[0].company)
    }
    return filteredList.map(item => item.company)
  }, [content])

  useEffect(() => {
    if (selectedItem) {
      const companyIndicators = [selectedItem.founders, selectedItem.stage, selectedItem.location, selectedItem.link]
      setnonNullCount(companyIndicators.filter(v => v !== null).length)
    }
  }, [selectedItem])

  return (
    <Container size='large'>
      <section className={`${styles.investmentsTeamSubpage}`}>
        <div className={`${styles.investmentsTeamSubpageTitle}`}>{name && `${name}’s `}Investments</div>
        <div className={`${styles.investmentsTeamSubpageWrapper}`}>
          <div className={`${styles.investmentsTeamSubpageContent}`}>
            <div className={`${styles.investmentsTeamSubpageContainer}`}>
              {selectedItem && (
                <Image imgObj={selectedItem.mainImage} className={styles.investmentsTeamSubpageImage} />
            )}
              {selectedItem && selectedItem.logoColor && (
                <div className={styles.investmentsTeamSubpageLogoWrap}>
                  <Image imgObj={selectedItem.logoColor} className={styles.investmentsTeamSubpageLogo} />
                </div>
              )}
            </div>
            <div className={`${styles.investmentsTeamSubpageInfoContainer}`} style={{ gridTemplateColumns: `repeat(${nonNullCount}, ${nonNullCount <= 2 ? '200px' : '1fr'})` }}>
              {selectedItem && selectedItem.founders && (
                <div className={`${styles.investmentsTeamSubpageInfoCard}`}>
                  <div className={`${styles.investmentsTeamSubpageInfoCardTitle}`}>Founders</div>
                  <div className={`${styles.investmentsTeamSubpageInfoText}`}>{selectedItem.founders}</div>
                </div>)}
              {selectedItem && selectedItem.stage && selectedItem.stage[0] && (
                <div className={`${styles.investmentsTeamSubpageInfoCard}`}>
                  <div className={`${styles.investmentsTeamSubpageInfoCardTitle}`}>Stage</div>
                  <div className={`${styles.investmentsTeamSubpageInfoText}`}>{selectedItem.stage[0].title}</div>
                </div>
              )}
              {selectedItem && selectedItem.location && (
                <div className={`${styles.investmentsTeamSubpageInfoCard}`}>
                  <div className={`${styles.investmentsTeamSubpageInfoCardTitle}`}>Location</div>
                  <div className={`${styles.investmentsTeamSubpageInfoText}`}>{selectedItem.location}</div>
                </div>
              )}
              {selectedItem && selectedItem.link && (
                <div className={`${styles.investmentsTeamSubpageInfoCard}`}>
                  <div className={`${styles.investmentsTeamSubpageInfoCardTitle}`}>Website</div>
                  <a target={isExternalUrl(selectedItem.link) ? '_blank' : '_self'} href={selectedItem.link} className={`${styles.investmentsTeamSubpageInfoLink}`} rel='noreferrer'>{getSiteAddressByLink(selectedItem.link)}</a>
                </div>
              )}
            </div>
          </div>
          <div className={`${styles.investmentsTeamSubpageScrollListContainer}`}>
            <div className={`${styles.investmentsTeamSubpageScrollListFade}`} />
            <div className={`${styles.investmentsTeamSubpageScrollList}`}>
              {
                filteredContent.map((item, i) => {
                  return (
                    <div key={item._id} onClick={() => setSelectedItem(item)} className={`${styles.investmentsTeamSubpageScrollListItem}`}>
                      {/* {mainLetterList && mainLetterList.length > 0 && mainLetterList.length === filteredContent.length && ( */}
                      {/*  <pre className={`${styles.investmentsTeamSubpageScrollListItemLetter}`}>{mainLetterList[i]}</pre> */}
                      {/* )} */}
                      <div className={`${styles.investmentsTeamSubpageScrollListItemText} ${selectedItem && selectedItem.title === item.title ? styles.investmentsTeamSubpageScrollListItemTextSelected : ''}`}>{item.title}</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className={`${styles.investmentsTeamSubpageMobileContainer}`}>
            <div className={`${styles.investmentsTeamSubpageMobileScrollListContainer}`}>
              <div className={`${styles.investmentsTeamSubpageScrollListFade}`} />
              <div className={`${styles.investmentsTeamSubpageScrollList}`}>
                {
                  filteredContent.map((item, i) => {
                    return (
                      <div key={item._id} onClick={() => setSelectedItem(item)} className={`${styles.investmentsTeamSubpageScrollListItem}`}>
                        <div className={`${styles.investmentsTeamSubpageScrollListItemText} ${selectedItem && selectedItem.title === item.title ? styles.investmentsTeamSubpageScrollListItemTextSelected : ''}`}>{item.title}</div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className={`${styles.investmentsTeamSubpageMobileInfoContainer}`}>
              {selectedItem && selectedItem.logoColor && (
                <Image imgObj={selectedItem.logoColor} className={styles.investmentsTeamSubpageMobileLogo} />
              )}
              {selectedItem && selectedItem.stage && selectedItem.founders && (
                <div className={`${styles.investmentsTeamSubpageInfoCard}`}>
                  <div className={`${styles.investmentsTeamSubpageInfoCardTitle}`}>Founders</div>
                  <div className={`${styles.investmentsTeamSubpageInfoText}`}>{selectedItem.founders}</div>
                </div>
              )}
              {selectedItem && selectedItem.stage && selectedItem.stage[0] && (
                <div className={`${styles.investmentsTeamSubpageInfoCard}`}>
                  <div className={`${styles.investmentsTeamSubpageInfoCardTitle}`}>Stage</div>
                  <div className={`${styles.investmentsTeamSubpageInfoText}`}>{selectedItem.stage[0].title}</div>
                </div>
              )}
              {selectedItem && selectedItem.stage && selectedItem.location && (
                <div className={`${styles.investmentsTeamSubpageInfoCard}`}>
                  <div className={`${styles.investmentsTeamSubpageInfoCardTitle}`}>Location</div>
                  <div className={`${styles.investmentsTeamSubpageInfoText}`}>{selectedItem.location}</div>
                </div>
              )}
              {selectedItem && selectedItem.link && (
                <div className={`${styles.investmentsTeamSubpageInfoCard}`}>
                  <div className={`${styles.investmentsTeamSubpageInfoCardTitle}`}>Website</div>
                  <a target={isExternalUrl(selectedItem.link) ? '_blank' : '_self'} href={selectedItem.link} className={`${styles.investmentsTeamSubpageInfoLink}`} rel='noreferrer'>{getSiteAddressByLink(selectedItem.link)}</a>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </Container>
  )
}

export default Investments
