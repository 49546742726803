import React, { useEffect, useState } from 'react'
import NewsPostPreview from './news-post-preview'
import Container from '../components/container'

import styles from './news-post-preview-grid.module.css'

function NewsPostPreviewGrid ({
  name = '',
  title = '',
  nodes = [],
  portfolioNodes = [],
  redpointNodes = [],
  teamNodes = [],
  eventsNodes = [],
  showMax = null
}) {
  const [nodesToRender, setNodesToRender] = useState([])

  useEffect(() => {
    const allNodes = portfolioNodes.concat(redpointNodes).concat(teamNodes).concat(eventsNodes).concat(nodes)
    if (showMax) {
      setNodesToRender(allNodes.slice(0, showMax))
    } else {
      setNodesToRender(allNodes)
    }
  }, [showMax])

  return (
    <Container>
      <div className={`${styles.root}`} id='news'>
        {title && (
          <div className={styles.titleBar}>
            <h3 className={`${styles.headline}`}>{title}</h3>
          </div>
        )}
        {!title && name && (
          <div className={styles.titleBar}>
            <p className={`${styles.headline}`}>From </p>
            <p className={`${styles.nameInTitle}`}>{name}</p>
          </div>
        )}

        <div className={styles.blockContainer}>
          {
            nodesToRender.length !== 0 && nodesToRender.map(node => (
              <div key={node._id}>
                <NewsPostPreview data={{ ...node }} categories={node.categories ?? null} />
              </div>
            ))
          }
        </div>
      </div>
    </Container>
  )
}

export default NewsPostPreviewGrid
