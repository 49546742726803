import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Person from '../components/person'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  query PersonTemplateQuery($id: String!, $slug: String!) {
    person: sanityPerson(id: { eq: $id }) {
        _rawBio
        name
        position
        youtubePlayer
        videoId
        mobileVideoId
        profileColor {
          asset {
            _id
            url
          }
        }
        focus {
            title
            slug {
              current
            }
        }
        id
        heroImage {
          asset {
            _id
            url
          }
        }
        socialLinkedin
        socialInstagram
        socialSubstack
        socialTwitter
        relatedCompaniesSort {
            order
            company {
                title
                _id
                _key
                slug {
                    current
                }
                _rawExcerpt
                link
                location
                founders
                logoColor {
                    asset {
                        _id
                        url
                    }
                }
                logo {
                    asset {
                        _id
                        url
                    }
                }
                brandHex {
                    hex
                }
                mainImage {
                    asset {
                        _id
                        url
                    }
                }
                sectors {
                    _id
                    title
                }
                stage {
                    _id
                    title
                }
            }
        }
        relatedArticles {
          _id
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          link
          tags {
              title
              slug {
                  current
              }
          }
          publication
          categories
          logo {
            asset {
                _id
                url
              }
          }
        }
        team
        department
        metaTitle
        metaDescription
        metaKeywords
    }
    written: allSanityWritten(filter: {author: {elemMatch: {slug: {current: {eq: $slug }}}}}, sort: {order: DESC, fields: publishedAt}) {
        nodes {
            _id
            _type
            tags {
                title
                slug {
                    current
                }
            }
            title
            blurb
            slug {
                current
            }
            featureImage {
                asset {
                    url
                    _id
                }
            }
        }
    }
  }
`

const PersonTemplate = props => {
  const { data, errors } = props
  const person = {
    ...(data?.person || {}),
    personArticles: data?.written?.nodes || []
  }

  const hideColor = person.relatedArticles.length === 0

  person.relatedArticles = (person.relatedArticles || []).filter(node => !node._id.startsWith('drafts.'))

  return (
    <Layout hideContactBar palette='redHead' hideColor={hideColor}>
      {errors && <SEO title='GraphQL Error' />}
      {person && <SEO title={person.metaTitle || person.name} description={person.metaDescription} keywords={person.metaKeywords} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {!errors && person && <Person {...person} />}
    </Layout>
  )
}

export default PersonTemplate
