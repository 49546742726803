import React from 'react'
import Icon from './icons'

import styles from './news-post-preview.module.css'
import { Link } from 'gatsby'
import { Image } from './Image'

function NewsPostPreview ({
  categories,
  data
}) {
  return (
    <Link className={styles.newsPostCard} to={`/content-hub/written/${data.slug.current}`}>
      <div className={styles.newsPostCardImageContainer}>
        {data && data.featureImage && (
          <Image imgObj={data.featureImage} className={styles.newsPostCardImage} />
        )}
      </div>
      <div className={styles.newsPostCardTextContainer}>
        <div className={styles.newsPostCardIconCircle} />
        <div className={styles.newsPostCardIcon}>
          <Icon symbol='newsArrowIcon' />
        </div>
        <div className={styles.newsPostCardIconSecondary}>
          <Icon symbol='newsArrowIcon' />
        </div>
        {(data.tags?.length > 0) && (
          <div className={styles.tags}>
            {data.tags.map((tag, tagIndex) => (
              <Link
                key={tagIndex}
                to={`/content-hub/category/${tag.slug?.current}/`}
                className={styles.tag}
                style={{fontSize: 12}}
              >
                {tag.title}
              </Link>
            ))}
          </div>
        )}
        {data && data.title && (
          <div className={styles.newsPostCardTextTitle}>{data.title}</div>
        )}
      </div>
    </Link>
  )
}

export default NewsPostPreview
